<template>
    <div class="objective-form">
      <!-- Team New Okr -->
      <b-card class="shadow-none mb-1" v-if="this.type == 'Team'">
        <b-row>
          <b-col sm="12" md="6" class="px-50">
            <label>Team</label>
            <b-button v-if="$can('Zircly Admin')"
              v-b-modal.division-modal
              size="sm"
              variant="outline-light"
              class="border-0 p-0 add-team"
              style="margin-left: 5px;"
            >
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
                class="text-warning"
            /></b-button>
            <v-select
                clas="w-100"
                v-model="objectiveData.team_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="teamOptions"
                :reduce="(label) => label.label"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="team_name"
                placeholder="Select Team"
              />
          </b-col>
          <b-col sm="12" md="6" class="px-50">
            <label>OKR Owner</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="objectiveData.okr_owner"
              :options="employeeOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee"
            />
          </b-col>
          <!-- <b-col sm="12" md="2" class="px-50">
            <label>Year</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="objectiveData.year"
              :options="yearOptions()"
              class="w-100"
              placeholder="Select year"
            />
          </b-col> -->
          <!-- <b-col sm="12" md="2" class="px-50">
            <label>Quarter</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="objectiveData.quarter"
              :options="quarterOptions"
              class="w-100"
              placeholder="Q1"
            /> 
          </b-col> -->
        </b-row>
      </b-card>
      <!-- End Team New Okr -->
      <!-- Organisation New Okr -->
      <b-card class="shadow-none mb-1" v-if="this.type == 'Organisation'">
        <b-row>
          <b-col sm="12" md="12" class="px-50">
            <label>OKR Owner</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="objectiveData.okr_owner"
              :options="employeeOptions"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee"
            />
          </b-col>
          <!-- <b-col sm="12" md="3" class="px-50">
            <label>Year</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="objectiveData.year"
              :options="yearOptions()"
              class="w-100"
              placeholder="Select year"
            />
          </b-col>
          <b-col sm="12" md="3" class="px-50">
            <label>Quarter</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="objectiveData.quarter"
              :options="quarterOptions"
              class="w-100"
              placeholder="Q1"
            />
          </b-col> -->
        </b-row>
      </b-card>
      <!-- End Organisation New Okr -->
            <b-card class="shadow-none mb-0">
              <div class="object-title mb-50"><label>Objective</label></div>
              <div class="object-form">
                <b-input-group> 
                  <validation-provider
                    #default="{ errors }"
                    name="Objective Title"
                  >
                  <b-form-input v-model="objectiveData.title" placeholder="Objective Title"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  
                  <b-dropdown :text="objectiveData.status" variant="secondary">
                    <b-dropdown-item @click="objectiveData.status = status" v-for="status in statusOptions" >{{status}}</b-dropdown-item>
                  </b-dropdown>
                </b-input-group>
                <b-row class="mt-2" v-if="objectiveData.type != 'Organisation' ">
                  <b-col sm="12">
                    <label>Alignment</label>
                  </b-col>
                  <b-col sm="6" md="4">
                    <b-form-group>
                      <v-select
                        :options="parentTypeOptions"
                        label="label"
                        :clearable="true"
                        :searchable="true"
                        input-id="parent_okr_type"
                        v-model="objectiveData.parent_objective_type"
                        name="parent_okr_type"
                        placeholder="Parent OKR Type"
                      />
                      </b-form-group>
                  </b-col>
                  <b-col sm="6" md="8" class="parent-nameokr">
                    <b-form-group>
                        <!-- <v-select
                          :options="parentOptions"
                          label="label"
                          :clearable="true"
                          :searchable="true"
                          :reduce="(val) => val.code"
                          v-model="objectiveData.parent_objective_id"
                          placeholder="Parent OKR"
                        /> -->
                      <b-dropdown variant="outline-secondary" :text="getLabel(objectiveData.parent_objective_id)" placeholder="Parent OKR" class="w-100">
                        <b-dropdown-item @click="objectiveData.parent_objective_id = parentokr.code"
                          v-for="parentokr in parentOptions">{{ parentokr.label }}</b-dropdown-item>
                      </b-dropdown>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            
            
        <ul class="m-0 p-0 tree h-100">
          <li :class="(objectiveData.hashid) ? 'list-unstyled edit-okrform' : 'list-unstyled'">
            <ul class="m-0 sub-tree p-0">
              <li class="list-unstyled" v-for="(key_result,k) in objectiveData.key_results" :key="k">
                <div class="shadow-none add-key position-relative pt-1 rounded mb-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="delete-icon cursor-pointer" fill="none"  v-if="objectiveData.key_results.length > 1" @click="confirmDeleteKeyResult(k)">
                    <g clip-path="url(#clip0_7712_57799)">
                      <path d="M2.66602 4.66602H13.3327" stroke="#FF471F" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.66602 7.33398V11.334" stroke="#FF471F" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9.33398 7.33398V11.334" stroke="#FF471F" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.33398 4.66602L4.00065 12.666C4.00065 13.0196 4.14113 13.3588 4.39118 13.6088C4.64122 13.8589 4.98036 13.9993 5.33398 13.9993H10.6673C11.0209 13.9993 11.3601 13.8589 11.6101 13.6088C11.8602 13.3588 12.0007 13.0196 12.0007 12.666L12.6673 4.66602" stroke="#FF471F" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667" stroke="#FF471F" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_7712_57799">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <div class="object-title mb-50"><label>Key Result #{{ k+1 }}</label></div>
                  <div class="object-form">
                    <textarea
                      :id="'key_result-'+(k+1)"
                      v-model="objectiveData.key_results[k].result_description"
                      placeholder="Type measurable outcomes will you need to achieve"
                      class="w-100 form-control auto-growth"
                      rows="1"
                    ></textarea>
                    <b-row class="mt-2 results-bottom">
                      <b-col sm="12" md="6" class="metric-select d-md-flex align-items-center">
                        <label class="mr-75 label-size mb-0">Metric Type</label>
                        <b-form-group class="mb-0">
                          <div class="ids-btn-group d-flex align-items-center">
                            <span class="tooltip-container"  @click="objectiveData.key_results[k].metric_type = 'Percentage'">
                              <button class="group-item is-icon-only" v-b-tooltip.hover.v-default title="Percentage">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <g clip-path="url(#clip0_7712_58822)">
                                      <path d="M16.5 17C16.5 17.2652 16.6054 17.5196 16.7929 17.7071C16.9804 17.8946 17.2348 18 17.5 18C17.7652 18 18.0196 17.8946 18.2071 17.7071C18.3946 17.5196 18.5 17.2652 18.5 17C18.5 16.7348 18.3946 16.4804 18.2071 16.2929C18.0196 16.1054 17.7652 16 17.5 16C17.2348 16 16.9804 16.1054 16.7929 16.2929C16.6054 16.4804 16.5 16.7348 16.5 17Z" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M6.5 7C6.5 7.26522 6.60536 7.51957 6.79289 7.70711C6.98043 7.89464 7.23478 8 7.5 8C7.76522 8 8.01957 7.89464 8.20711 7.70711C8.39464 7.51957 8.5 7.26522 8.5 7C8.5 6.73478 8.39464 6.48043 8.20711 6.29289C8.01957 6.10536 7.76522 6 7.5 6C7.23478 6 6.98043 6.10536 6.79289 6.29289C6.60536 6.48043 6.5 6.73478 6.5 7Z" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M6.5 18L18.5 6" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_7712_58822">
                                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                      </clipPath>
                                    </defs>
                                  </svg>
                              </button>
                              </span>
                            <span class="tooltip-container" @click="objectiveData.key_results[k].metric_type = 'Number'">
                              <button class="group-item is-selected" v-b-tooltip.hover.v-default title="Numeric">
                              <label class="cursor-pointer mb-0">123</label>
                              </button>
                            </span>
                              <span class="tooltip-container"  @click="objectiveData.key_results[k].metric_type = 'Boolean'">
                                <button class="group-item is-icon-only" v-b-tooltip.hover.v-default title="Yes / No">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <g clip-path="url(#clip0_7712_58869)">
                                      <path d="M3.5 12C3.5 13.1819 3.73279 14.3522 4.18508 15.4442C4.63738 16.5361 5.30031 17.5282 6.13604 18.364C6.97177 19.1997 7.96392 19.8626 9.05585 20.3149C10.1478 20.7672 11.3181 21 12.5 21C13.6819 21 14.8522 20.7672 15.9442 20.3149C17.0361 19.8626 18.0282 19.1997 18.864 18.364C19.6997 17.5282 20.3626 16.5361 20.8149 15.4442C21.2672 14.3522 21.5 13.1819 21.5 12C21.5 10.8181 21.2672 9.64778 20.8149 8.55585C20.3626 7.46392 19.6997 6.47177 18.864 5.63604C18.0282 4.80031 17.0361 4.13738 15.9442 3.68508C14.8522 3.23279 13.6819 3 12.5 3C11.3181 3 10.1478 3.23279 9.05585 3.68508C7.96392 4.13738 6.97177 4.80031 6.13604 5.63604C5.30031 6.47177 4.63738 7.46392 4.18508 8.55585C3.73279 9.64778 3.5 10.8181 3.5 12Z" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      <path d="M9.5 12L11.5 14L15.5 10" stroke="#C3C3C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_7712_58869">
                                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                </span>
                              </div>
                          </b-form-group>
                      </b-col>
                      <b-col sm="12" md="3" v-if="objectiveData.key_results[k].metric_type != 'Boolean'" class="startgoal d-md-flex align-items-center">
                        <label class="mr-75 mb-0">Start</label>
                        <b-form-group class="mb-0">
                          <b-form-input 
                          v-model="objectiveData.key_results[k].start" v-on:change="objectiveData.key_results[k].progress = objectiveData.key_results[k].start" />
                          <b-input-group-append is-text v-if="objectiveData.key_results[k].metric_type == 'Percentage'">
                            <feather-icon
                              icon="PercentIcon"
                              class="cursor-pointer"
                              style="color: #A1A1A1"
                            />
                          </b-input-group-append>
                          </b-form-group>
                      </b-col>
                      <b-col sm="12" md="3" v-if="objectiveData.key_results[k].metric_type != 'Boolean'" class="startgoal d-md-flex align-items-center">
                        <label class="mr-75 mb-0">Goal</label>
                        <b-form-group class="mb-0">
                          <b-form-input 
                          v-model="objectiveData.key_results[k].goal"/>
                          <b-input-group-append is-text v-if="objectiveData.key_results[k].metric_type == 'Percentage'">
                            <feather-icon
                              icon="PercentIcon"
                              class="cursor-pointer"
                              style="color: #A1A1A1"
                            />
                          </b-input-group-append>
                          </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </b-card>
        <!-- Modals -->
        <b-modal
      id="division-modal"
      ref="modal"
      size="md"
      title="Submit Team Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDivision"
    >
      <template #modal-header="{ close }">
        <h5>Add Team Name</h5>
        <b-button size="md" variant="outline-dark" @click="close()" class="close">
          <feather-icon class="cursor-pointer" icon="XIcon" width="16px"/>
        </b-button>
      </template>
      <form ref="form" @submit.stop.prevent="addDivision" class="mt-1">
        <b-form-group
          label="Division Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="divisionNameState"
        >
          <b-form-input
            id="name-input"
            v-model="divisionName"
            :state="divisionNameState"
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="outline-secondary" @click="cancel()" class="btn ml-1 cancel-btn">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()" class="btn yes-btn"> Save </b-button>
      </template>
    </b-modal>
        </div>
</template>
<script>
import { required, email, numeric } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
export default {
  props:{
    teamOptions:{
      type:Array,
      required:true,
    },
    employeeOptions:{
      type:Array,
      required:true,
    },
    itemData:{
      required:false,
    },
    type:{
      type:String,
      required:false,
    },
    parentTypeOptions:{
      type:Array,
      required:true,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      metricTypeoptions:['Percentage', 'Number', 'Boolean'],
      statusOptions:['Upcoming','Not Started Yet', 'In Progress', 'Completed', 'Cancelled','On Hold','Deferred'],
      divisionName: null,
      divisionNameState: null,
      quarterOptions:['Q1','Q2','Q3','Q4'],
      userData: this.$cookies.get('userData'),
      parentOptions:[],
      objectiveData:{
        title:'',
        team_name:this.$cookies.get('userData').division,
        okr_owner:this.$cookies.get('userData').hashid,
        year:this.getCurrentYear(),
        quarter:this.getCurrentQuarter(),
        parent_objective_id:'',
        parent_objective_type:'',
        status:'In Progress',
        type:this.type,
        key_results:[
          {
            result_description:'',
            metric_type:'Percentage',
            start:0,
            progress:0,
            goal:100,
          }
        ]
      }
    };
  },
  methods: {
    confirmDeleteKeyResult(key) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete Key Result #${key+1}.`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeKeyResult(key);
        }
      });
    },
    getParentObjectives(type){
      axios.get("/objectives/getList/"+type).then((res) => {
        this.parentOptions = res.data.data;
      }).catch((error) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Oops! Unable to fetch Parent Objectives",
            icon: "BellIcon",
            variant: "danger",
            text: error,
          },
        });
      });
    },
    yearOptions(){
      return ['2023','2024'];
    },
    resetModal() {
      this.divisionName = "";
    },
    addDivision() {
      const self = this;
      axios
        .post("/divisions", { name: self.divisionName })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Division Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit('refresh-division', true);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Division Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    addKeyResult(){
      this.objectiveData.key_results.push({
            result_description:'',
            metric_type:'Number',
            start:0,
            progress:0,
            goal:100,
            state:''
          });
      var id = 'key_result-'+(this.objectiveData.key_results.length);
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.focus();
      }
      this.$nextTick(() => {
      this.setUpTextArea();
      });
    },
    resetObjective(){
      this.objectiveData = {
        title:'',
        parent_objective_id:'',
        parent_objective_type:this.type,
        status:'In Progress',
        type:this.type,
        team_name:this.$cookies.get('userData').division,
        okr_owner:this.$cookies.get('userData').hashid,
        year:this.getCurrentYear(),
        quarter:this.getCurrentQuarter(),
        key_results:[
          {
            result_description:'',
            metric_type:'Number',
            start:0,
            goal:100,
            progress:0,
            state:''
          }
        ]
      };
    },
    getCurrentYear(){
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    getCurrentQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so add 1

      // Calculate the quarter based on the current month
      if (currentMonth >= 1 && currentMonth <= 3) {
        return 'Q1';
      } else if (currentMonth >= 4 && currentMonth <= 6) {
        return 'Q2';
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        return 'Q3';
      } else {
        return 'Q4';

      }
    },
    addObjectiveToDB(action = 'create'){
      var validData = this.checkData(this.objectiveData);
      if(validData.status){
        this.$emit('objective-submitted', true);
        if(action == 'create'){
          axios.post(`objectives`, this.objectiveData)
          .then((res) => {
            if(res.data.success == false){
              this.$emit('objective-submitted', 'release');
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't Add OKR",
                  icon: "BellIcon",
                  variant: "danger",
                  text: res.data.message,
                },
              });
              return;
            }
            this.$emit('objective-submitted', false);
              this.objectiveData = {
                title:'',
                parent_objective_id:'',
                parent_objective_type:this.type,
                status:'In Progress',
                type:this.type,
                team_name:this.$cookies.get('userData').division,
                okr_owner:this.$cookies.get('userData').hashid,
                year:this.getCurrentYear(),
                quarter:this.getCurrentQuarter(),
                key_results:[
                  {
                    result_description:'',
                    metric_type:'Number',
                    start:0,
                    goal:100,
                    progress:0,
                    state:''
                  }
                ]
              };
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "OKR Added Successfully",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
          })
          .catch((error) => {
            this.$emit('objective-submitted', false);
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Oops! OKR Adding Failed",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error,
                },
              });
          });
        }else{
          axios.patch('objectives/'+this.objectiveData.hashid, this.objectiveData)
          .then((res) => {
              this.$emit('objective-submitted', false);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "OKR Updated Successfully",
                  icon: "BellIcon",
                  variant: "success",
                  text: res.data.message,
                },
              });
          })
          .catch((error) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Oops! OKR Adding Failed",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error,
                },
              });
          });
        }
      }
      else{
        for(var key in validData.messages){
         this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                text:validData.messages[key].text,
                icon: "BellIcon",
                variant: "danger",
              },
            }); 
        }
      }

    },
    addObjective(objective){
      delete objective["hashid"];
      this.objectiveData = objective;
      this.addObjectiveToDB();
      this.resetObjective();
    },
    checkData(objectiveData) {
      var messages = [];
      if (objectiveData.title == ''){
        messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
      }
      // if (objectiveData.type != 'individual'){
      //   messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
      // }
      objectiveData.key_results.forEach(function(key_result,key) { 
        if(key_result.result_description == '' || key_result.result_description == null ){
          messages.push({'title':"Key Result is required #"+(key+1),'text':"Please Enter Key Result #"+(key+1)+" for Objective "});
        }
        if(key_result.metric_type == 'Number' || key_result.metric_type == 'Percentage' ){
          if(key_result.goal == '' || key_result.goal == null || key_result.goal == 0 || !(!isNaN(key_result.goal) && !isNaN(parseInt(key_result.goal)))){
            messages.push({'title':"Goal "+key_result.metric_type+" is required #"+(key+1),'text':"Please Valid Enter Goal "+key_result.metric_type+" for Key Result #"+(key+1)});
          }
          if((key_result.start < 0 || key_result.start == '' || key_result.start == null  || !(!isNaN(key_result.start) && !isNaN(parseInt(key_result.start))))){
            if(key_result.start != 0){
              if(key_result.start < 0){
                messages.push({'title':"Start "+key_result.metric_type+" is invalid #"+(key+1),'text':"Start "+key_result.metric_type+ "must be zero or positive for Key Result #"+(key+1)});
              }else{
                messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Please Enter Valid Start for Key Result #"+(key+1)});
              }
            }
          }
          if(parseInt(key_result.start) >= parseInt(key_result.goal)){
            messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Start must be less than Target for Key Result #"+(key+1)});
          }
          if(key_result.progress && parseInt(key_result.progress) < parseInt(key_result.start)){
            messages.push({'title':"Progress "+key_result.metric_type+" is not valid for #"+(key+1),'text':"Progress must be grater than Start for Key Result #"+(key+1)});
          }
          
        }
      });

      var response = {"status":false,'messages':messages};
      if(messages.length == 0){
        var response = {"status":true,'messages':messages};
      }
      return response;      
    },
    getLabel(code){
      if(code){
        return this.parentOptions.find(parentOkr => parentOkr.code === code).label;
      }
      else {
        return 'Parent OKR';
      }
    },
    removeKeyResult(key){
      this.objectiveData.key_results.splice(key, 1)
    },
    setUpTextArea(){
      // Auto growth textarea
      var textareas =  document.getElementsByClassName('auto-growth');
      // Loop through the elements and perform some action
      for (var i = 0; i < textareas.length; i++) {
          var textarea = textareas[i];
          textarea.addEventListener('keydown', autosize);
            function autosize(){
              var el = this;
              setTimeout(function(){
                el.style.cssText = 'height:auto; padding:0';
                el.style.cssText = 'height:' + el.scrollHeight + 'px';
              },0);
            }
          // End Auto growth textarea
      }
      
    }
  },
  created() {
    if(this.objectiveData.parent_objective_type){
    this.getParentObjectives(this.objectiveData.parent_objective_type);
    }else{
    this.getParentObjectives(this.type);
    }
    const savedData = localStorage.getItem('objectiveData');
    if (savedData) {
      this.objectiveData = JSON.parse(savedData);
    }
  },
  watch: {
    'objectiveData.parent_objective_type': function(newValue, oldValue) {
      this.objectiveData.parent_objective_id = '';
      this.getParentObjectives(newValue);
    },
    objectiveData: {
      handler(newData) {
        // Save form data to localStorage on every change
        localStorage.setItem('objectiveData', JSON.stringify(newData));
      },
      deep: true // Enable deep watching to detect changes in nested properties
    }
  },
  mounted() {
    if(this.itemData){
      this.objectiveData = this.itemData;
    }
    this.setUpTextArea();
    
  },
}
</script>